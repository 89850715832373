

import React from 'react'



class RestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
  this.fetch ()
  }


fetch ()  {     fetch("https://gesicht.pythonanywhere.com/kb_output", { method: 'get', mode: 'cors' })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      ) }


  componentDidUpdate(prevprops) {
    if (prevprops.needsupdate !== this.props.needsupdate) { this.fetch()}
  }


  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <>
          {items.reverse().map((item, index) => (
            <p key={index}>
              {item.message}
            </p>
          ))}
        </>
      );
    }
  }
}

// {items.map(item => (
//   <li key={item.name}>
//     {item.name} {item.message}


class Post extends React.Component {


  componentDidUpdate(){
    this.textInput.focus();
  }


  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }



  handleSubmit(event) {
    event.preventDefault();

    fetch("https://gesicht.pythonanywhere.com/kb_input", {     method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }, body: JSON.stringify ({ message :this.state.value})   }).then((  ) => {

      console.log(this.props.onChange)
    this.props.onChange()
  })


    this.setState({value: ""});
  }



    focus () {
      document.getElementById('text_field').focus();
    }





      render() {
        return (
          <form onSubmit={this.handleSubmit}>


              <span id="black">
              <input type="text" maxLength="60" value={this.state.value} size={45} onChange={this.handleChange} ref={(input) => { this.textInput = input; }} id="text_field" onBlur={this.focus}  autoFocus  />
              <span id="Cursor">|</span>
              </span>

            <input type="submit" value=" ENTER ↵" />
            <p id='answer'></p>
            <a id='answer_link' href=""></a>

          </form>
        );
      }
    }





    class ChatPostFetch extends React.Component {

      constructor(props) {
        super(props);
        this.state = {needsupdate: true};


      }




      onChange() {
        this.setState ({needsupdate:!this.state.needsupdate})

        console.log(this.state.needsupdate)
      }

      render() { return(

        <>
        <Post onChange={this.onChange.bind(this)} />
      <RestComponent needsupdate={this.state.needsupdate} />


      </>
    )}
     }



export default ChatPostFetch;
