import React from 'react'
import {Deutsch, English, Type, LinkHOME, SiteTitle} from '../components/linksetc.js'

import GrayStyles from '../components/gray.module.css'
import '../components/basic.css'

import ChatPostFetch from '../components/chatpostfetch'



const GrayPage = ( {location} ) => (


  <div className={GrayStyles.Gray}>

  <SiteTitle string="Write your own text" />


              <English>
                <Type>
              Type something and press Enter. Or go <LinkHOME location={location}>home</LinkHOME>:
              </Type>
              </English>

              <Deutsch>
                <Type>
              Tippen und Enter drücken. Oder gehe <LinkHOME location={location}>zurück</LinkHOME>:
              </Type>
              </Deutsch>

              <p/>
<ChatPostFetch />

</div>
)

export default GrayPage
